/* cyrillic-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfCRc4EsA.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfABc4EsA.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfCBc4EsA.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfBxc4EsA.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfCxc4EsA.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfChc4EsA.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfBBc4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

::-moz-selection {
    /* Code for Firefox */
    color: var(--primary-color);
    background: var(--light-blue);
}
::selection {
    color: var(--primary-color);
    background: var(--light-blue);
}
html * {
    font-family: "Roboto", sans-serif;
}

html {
    height: 100%;
    position: relative;
}
body {
    background-color: #ffffff;
}
a {
    color: #495057;
    text-decoration: none !important;
    background-color: transparent;
}
a:hover {
    color: var(--primary);

}

textarea:hover,
input[type="text"]:hover,
textarea:active,
input[type="text"]:active,
textarea:focus,
input[type="text"]:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}
h1 {
    font-size: 2.6em;
}
h2 {
    font-size: 1.6em;
}
h3 {
    font-size: 1.3em;
}
ul {
    list-style-type: none;
    margin:0;
    padding:0;
  }
:root {
    --primary: #6878f4;
    --secondary: #62cbdf;
    --primary-color: #6878f4;
    --primary-color-hover: #4a56af;
    --primary-color-disabled: #bac1f3;
    --secondary-color-hover: #4a9baa;
    --lila: #8e6dfb;
    --naranja: #f2b95d;
    --verde: #62cbdf;
    --light-green: #e4f1e9;
    --rosa: #d483e7;
    --grey-dark: #687888;
    --grey-dark2: #828589;
    --grey: #ededf7;
    --light-red: #ff9a9a;
    --light-blue: #f3f9ff;
    --light-blue3: #f7fbff;
    --light-blue2: #b9d6ff;
    --light-black:#212529;
    --facebook-color: #3b5998;
    --google-color: #db4a39;
    --tiny-radius: 0.5rem;
    --radius: 0.8rem;
    --big-radius: 2rem;
    --super-radius: 8rem;
    --info: rgb(82, 189, 206);
}
.list-links{
    text-decoration:none !important;
    color:var(--primary);

}
.list-links:hover{
    color:var(--primary);
}

.big-container {
  max-width: 1600px;
}
.search-section {
    width: 100%;
    transition: width 0.5s;
    transition-property: width;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: 0s;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}
.filters.open {
    opacity: 1;
    margin-left: 0;
}
.filters {
    width: 258px;
    margin-left: -258px;
    position: sticky;
    height: calc(100vh);
    top: 0;
    z-index: 100;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.5s;
    background-color: #fff;
    border-right: 1px solid var(--grey);
}


.sidebar-filters {
    width: 245px;
    background: #fff;
    z-index: 500;
}
.sidebar-left {
    background: #fff;
    position: -webkit-sticky;
    position: sticky;
    height: calc(100vh);
    top: 0;
    background-color: #fff;
    display: flex;
    border-top:1px solid var(--grey);
    border-right: 1px solid var(--grey);
}
.border-bottom-shadow{
    box-shadow: 0 2px 4px rgba(95, 97, 100, 0.08), 0 8px 12px rgba(95, 97, 100, 0.04);
}
.sidebar-left .list-group-item{
    border:none;
}
.bg-orange {
    background-color: var(--naranja);
}
.bg-lila {
    background-color: var(--primary-color);
}
.bg-rosa {
    background-color: var(--rosa);
}
.bg-green {
    background-color: var(--verde) !important;
}
.bg-light-green {
    background-color: var(--light-green) !important;
}
.bg-grey {
    background-color: var(--grey) !important;
}
.bg-black {
    background-color: #000 !important;
}
.bg-grey-dark {
    background-color: var(--grey-dark) !important;
}
.bg-grey-dark2 {
    background-color: var(--grey-dark2) !important;
}
.bg-light-blue {
    background-color: var(--light-blue) !important;
}
.bg-white-transparent {
    background: rgba(255,255,255,0.5) !important;
}
.bg-light-blue3 {
    background-color: var(--light-blue3) !important;
}
.bg-light-blue2 {
    background-color: var(--light-blue2) !important;
}
.bg-light-red{
    background-color: var(--light-red) !important;
}
.bg-primary-disabled {
    background-color: var(--primary-color-disabled) !important;
}
.bg-transparent {
    background-color: transparent !important;
}
.bg-filtercolor-grey {
    background-color: #d6d3e4 !important;
}
.bg-filtercolor-pink {
    background-color: #ff84ca !important;
}
.bg-filtercolor-brown {
    background-color: #7f5c5e !important;
}
.bg-filtercolor-purple {
    background-color: #8964b7 !important;
}
.bg-filtercolor-blue {
    background-color: #339fe5 !important;
}
.bg-filtercolor-turquoise {
    background-color: #1bdac2 !important;
}
.bg-filtercolor-green {
    background-color: #18cc7d !important;
}
.bg-filtercolor-yellow {
    background-color: #fee856 !important;
}
.bg-filtercolor-red {
    background-color: #f03861 !important;
}
.bg-filtercolor-orange {
    background-color: #fb6f57 !important;
}
.primary-font-color {
    color: var(--primary-color);
}
.secondary-font-color {
    color: var(--secondary);
}
.bold-font {
    font-weight: 700 !important;
}
.bolder-font {
    font-weight: 900 !important;
}
.regular-font {
    font-weight: 400 !important;
}

.myDropdown
{
   overflow: auto;
}
.arrows {
    font-size: 10px;
    height: 8px;
    margin-left: 6px;
    width: 30px;
    color: var(--primary-color) !important;
    fill: currentColor;
}
.grey-color {
    color: var(--grey-dark) !important;
}
.light-dark-color {
    color: var(--light-dark) !important;
}
.orange-font {
    color: var(--naranja) !important;
}
.purple-font {
    color: var(--lila) !important;
}
.green-font {
    color: var(--verde) !important;
}

.dark-grey{
    color: var(--light-black) !important;
}

.bg-primary-grey-light-alt {
    background-color: var(--light-blue);
}

.over {
    border: solid 5px var(--primary-color);
}
.card-img-top {
    object-fit: scale-down;
    padding: 5px;
}
.embed-responsive {
    position: relative;
    display: block;
    height: 171px;
    overflow: hidden;
}
.thumbnail-active {
    border: solid 2px var(--light-blue2);
    border-bottom: solid 5px var(--light-blue2);
}
.bg-primary-grey-light {
    background-color: var(--light-blue);
}
.btn {
    border-radius: var(--radius);
}
.btn-link {
    color: var(--primary-color);
    text-decoration:none;
}
.btn-color {
    height: 29px;
    width: 29px;
    border-radius: var(--tiny-radius) !important;
}
.btn-filters {
    color: var(--grey-dark);
    background-color: var(--white);
    border-radius: var(--radius) !important;
    cursor: pointer;
}
.btn-filters:hover {
    color: white !important;
}
.btn-filters:hover [class^="icon-"]:before,
.btn-filters:hover .fa:before {
    color: var(--verde) !important;
}
.btn-filters:hover {
    color: var(--verde) !important;
    content: "\e971" !important;
}
.cursor-pointer {
    cursor: pointer;
}
.btn-link:hover {
    color: var(--primary-color);
}
.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none !important;
}
.btn-light-blue{
    background-color: var(--light-blue) !important;
    color:#343a40 !important;
    border-radius: var(--big-radius);
}
.btn-primary {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    border-radius: var(--big-radius);
}
.tiny-radius{
    border-radius: var(--tiny-radius) !important;
}

.btn-secondary{
    color: #fff;
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
    border-radius: var(--big-radius);
}
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:before {
    background-color: var(--secondary-color-hover) !important;
    border-color: var(--secondary-color-hover) !important;

}
.dropdown-menu.dropdown-menu-wide {
    width:100%;
    text-align: center;
}
.btn-secondary-alt,
.btn-secondary-alt:hover,
.btn-secondary-alt:active,
.btn-secondary-alt:before {
    color: #fff;
    background-color: var(--light-blue2) !important;
    border-color: var(--light-blue2) !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
    outline: none !important;
    color: #fff;
    background-color: var(--primary-color-hover) !important;
    border-color: var(--primary-color-hover) !important;
}
.btn-primary.btn.active, .btn-primary.btn.show {
    color: #fff !important;
    background-color: var(--primary-color-hover) !important;
    border-color: var(--primary-color-hover) !important;
}
.btn-primary-group {
    outline: none !important;
    background-color: white !important;
    border-color: var(--grey) !important;
}
.btn-primary-group-selected {
    outline: none !important;
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}
.selected{
    border: 2px solid var(--primary-color) !important;
}

.btn-primary:disabled {
    outline: none !important;
    color: var(--primary-color-disabled);
    background-color: var(--primary-grey-light) !important;
    border-color: var(--primary-grey-light) !important;
}
.btn-primary-light,
.btn-primary-light:hover,
.btn-primary-light:active,
.btn-primary-light:before {
    color: white;
    font-weight: bold;
    background-color: var(--naranja) !important;
}

.btn-outline-primary,
.btn-outline-primary:hover,
.btn-outline-primary:active {
    color: var(--primary-color) !important;
    border-color: var(--primary-color);
    background-color: white !important;
    border-radius: var(--big-radius);
}
.btn-outline {
    color: var(--light-black) !important;
    border-color: var(--grey) !important;
    background-color: white !important;
}
.btn-outline:hover,
.btn-outline:active {
    color: var(--verde) !important;
}
.btn-lg,
.btn-group-lg > .btn {
    padding: 0.6rem 1rem;
    font-size: 1.125rem;
    line-height: 1.5;
    border-radius: var(--radius);
    border-top-right-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
}

.container-4 {
    border-radius: 50px;
}
.container-4.input-group > .input-group-prepend > .btn,
.container-4.input-group {
    border-radius: 50px;

}
.container-4 input.search {
    height: 70px;
    font-size: 16pt;
    border: none;
}

.container-4 .dropdown-toggle {
    height: 54px;
    font-size: 12pt;
    border: none;
}

.container-4 .input-group-append {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.container-4 button.icon {
    background-color: var(--primary-color);
    height: 54px;
    width: 59px;
    color: whitesmoke;
    opacity: 1;
    font-size: 20pt;
    border-radius: 50px !important;
}
@media screen and (max-width: 768px) {

    .container-4 input.search {
        height: 60px;
        font-size: 16pt;
        border: none;
    }
    .container-4 button.icon {
        background-color: var(--primary-color);
        height: 44px;
        width: 49px;
        color: whitesmoke;
        opacity: 1;
        font-size: 16pt;
        border-radius: 50px !important;
    }
    .container-4 .dropdown-toggle{
        background-color: var(--primary-color);
        height: 44px;
        border-radius: 50px !important;
    }
    .filters.open {
        position: fixed;
        bottom: 0;
        z-index: 100;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        background-clip: padding-box;
        outline: 0;
        transition: transform 0.3s ease-in-out;
    }
    .notification{
        width:75% !important;
    }




}
.search-bar {
    background-color: var(--light-blue) !important;
    border-top-right-radius: var(--big-radius) !important;
    border-bottom-right-radius: var(--big-radius) !important;
    padding-left: 0px !important;
    outline: none !important;
}
.search-icon {
    background-color: var(--light-blue) !important;
    color: var(--grey-dark) !important;
    padding-top: 10px;
}
.primary-icon {
    color: var(--secondary) !important;
}
.orange-icon {
    color: var(--primary) !important;
}
.footer {
    grid-row-start: 2;
    grid-row-end: 3;
    background-color:white;
    border-top:1px solid var(--grey);
}

.footer a {
    text-decoration:none;
    color:#212529;
}
.footer a:hover{
    color: var(--primary);
}
.footer h4 {
    color: #bac8d3;
    font-weight: bold;
}
#page-loader,.page-loader {
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    justify-content:center;
    align-items:center;
}
#page-loader .preloader-interior,.page-loader .preloader-interior {
    display: block;
    position: relative;
    margin:auto;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--primary);
    -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
#page-loader .preloader-interior:before , .page-loader .preloader-interior:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--secondary);
    -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#page-loader .preloader-interior:after,.page-loader .preloader-interior:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--pink);
    -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}
.modal-content {
    border-radius: var(--radius);
}
.my-radius {
    border-radius: var(--radius) !important;
}
.my-big-radius {
    border-radius: var(--big-radius) !important;
}
.my-super-radius {
    border-radius: var(--super-radius) !important;
}
.my-big-right-radius {
    border-top-right-radius: var(--big-radius) !important;
    border-bottom-right-radius: var(--big-radius) !important;
}
.my-big-left-radius {
    border-top-left-radius: var(--big-radius) !important;
    border-bottom-left-radius: var(--big-radius) !important;
}
.my-left-radius {
    border-top-left-radius: var(--radius) !important;
    border-bottom-left-radius: var(--radius) !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.my-right-radius {
    border-top-right-radius: var(--radius) !important;
    border-bottom-right-radius: var(--radius) !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.modal-content h5 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--primary-grey-light);
    line-height: 0.1em;
    margin: 10px 0 20px;
}

.modal-content h5 span {
    background: #fff;
    padding: 0 10px;
}
.top-radius {
    border-top-left-radius: var(--radius) !important;
    border-top-right-radius: var(--radius) !important;
}
.form-control {
    border-radius: var(--radius);
}
.modal-footer {
    border-bottom-left-radius: var(--radius) !important;
    border-bottom-right-radius: var(--radius) !important;
}
.facebook-button {
    color: #fff;
    background-color: var(--facebook-color);
    border-color: var(--facebook-color);
}
.facebook-button:hover {
    color: #fff;
    background-color: var(--facebook-color) !important;
    border-color: var(--facebook-color) !important;
}
.google-button {
    color: #fff;
    background-color: var(--google-color);
    border-color: var(--google-color);
}
.google-button:hover {
    color: #fff;
    background-color: var(--google-color) !important;
    border-color: var(--google-color) !important;
}
.top-bar-message {
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    padding: 12px;
    line-height: 1.5;
    text-align: center;
    background-color: var(--info) !important;
}
.list-group-item.active {
    z-index: 2;
    color: var(--grey-dark);
    background-color: var(--primary-grey-light-alt);
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
}
.list-group-item:last-child {
    border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
}
.lang-select {
    max-width: 200px;
}
.dropdown-menu {

border: 1px solid #ececee;
border-radius: var(--radius) !important ;
box-shadow: 0 1px 8px rgba(0,0,0,.1);
color: #484848;
padding: 20px;
top: 100%;
}

.dropdown-item:first-child {
    border-top-left-radius: var(--big-radius);
    border-top-right-radius: var(--big-radius);
}
.dropdown-item:last-child {
    border-bottom-left-radius: var(--big-radius);
    border-bottom-right-radius: var(--big-radius);
}
.dropzone {
    border: 2px dashed var(--secondary) !important;
    border-radius: 5px;
    background: white;
    color: var(--grey-dark2) !important;
}
.ui-button.ui-state-active {
    border: 1px solid transparent;
    font-weight: bold;
    color: var(--primary-grey-light-alt) !important;
    background-color: var(--secondary) !important;
    box-shadow: none !important;
}
.ui-button.ui-state-active .text-muted {
    color: var(--primary-grey-light-alt) !important;
}
.icon-drop {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
}
.text-editor-wrap {
    padding: 0px;
    margin: 0px;
}
.iframe {
    border: 2px solid cyan;
    height: 10em;
    width: 30em;
}
.list {
    list-style: none;
}

.text-editor {
    display: none;
}
.output-div {
    padding: 10px;
    border: 1px solid gray;
    height: 10em;
    width: 30em;
}
#outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
}

#table-container {
    height: 100%;
    width: 100%;
    display: table;
}

#table-cell {
    vertical-align: middle;
    height: 100%;
    display: table-cell;
    border: 1px solid #000;
}
.navbar-search {
    border-bottom:1px solid var(--grey);
    background: #fff;
    margin-bottom: 1px !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
}
.heading {
    border-bottom: 1px solid var(--light-blue);
}
.my-panel {
    border: 1px solid var(--grey) !important;
    border-radius:var(--radius) !important;
    padding:20px;
}
.border-grey{
    border: 1px solid var(--grey);

}
[class^="preview-container"],
[class*=" preview-container"] {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    padding-bottom:20px;
}


.relateds-container {
    z-index: 10;
}
.break {
    flex-basis: 100%;
    height: 0;
}
.font-preview-subheading {
    font-size: 2rem;
}
table {
    border-collapse: separate;
    word-break: break-word;
  }
  .table th, .table td {
    border-top: 1px solid var(--grey);
  }
  .table thead th {
    border-bottom: 0px ;
  }

.nav-tabs {
    border-bottom: none;
}
.nav-link{
    color:var(--light-black);
}

.nav-tabs .nav-link {
    border-radius: 0rem !important;
    border: none;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 20px;
}
.nav-tabs .nav-link.disabled {
    color: var(--grey) !important;
}
.nav-tabs .nav-link,
.nav-tabs .show > .nav-link {
    color: var(--light-black);
    background-color: transparent !important;
    border-radius: none !important;
}
.nav-link:hover{
    color: var(--primary) !important;
}
.nav-tabs .nav-link.active,
.nav-tabs .show > .nav-link {
    color: var(--primary-color);
    border-bottom: 3px solid var(--primary-color);
}
.nav-tabs .nav-link {
    border-radius: 0.25rem;
}
.my-fixed-item {
    position: -webkit-sticky;
    position: sticky;
    top: 15px;
}
.dropdown-submenu {
    position: relative;
}

.dropdown-submenu a::after {
    transform: rotate(-90deg);
    position: absolute;
    right: 6px;
    top: 0.8em;
}

.dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
}

/* The slider itself */
.slider {
    -webkit-appearance: none; /* Override default CSS styles */
    height: 3px; /* Specified height */
    background: var(--grey-dark); /* Grey background */
    outline: none; /* Remove outline */
    border-radius: none !important;
    opacity: 1; /* Fully shown on mouse-over */
}

/* Mouse-over effects */
.slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */

    width: 18px; /* Set a specific slider handle width */
    height: 18px; /* Slider handle height */
    background: var(--primary-color); /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: var(--radius);
    border: none;
}

.slider::-moz-range-thumb {
    width: 18px; /* Set a specific slider handle width */
    height: 18px; /* Slider handle height */
    background: var(--primary-color); /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: var(--radius);
    border: none;
}
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid var(--grey);
    margin: 1em 0;
    padding: 0;
}
.hr-grey{
    border-top: 1px solid var(--grey) !important;
}
.dropdown-divider {

    border-top: 1px solid var(--light-blue);
  }

.verticalLine {
    border-left: 1px solid #fff;
}
.range-wrap {
    position: relative;
    margin: 5 auto 3rem;
}
.range {
    width: 100%;
}
.bubble {
    background: var(--grey-dark);
    color: white;
    padding: 1px 10px;
    position: absolute;
    border-radius: 4px;
    top: 25px;
    font-weight: bold;
    transform: translateX(-50%);
}
.bubble::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 2px;
    background: var(--grey-dark);
    top: -1px;
    left: 50%;
}

#glyphs {
    overflow: scroll;
    overflow-x: hidden; /* Hide horizontal scrollbar */
    padding: 10px;
}
.glyph {
    font-size: 75px !important;
    height: 100px;
}

.wrapper {
    float: left;
    border: 1px solid var(--grey);
    text-align: center;
}
.wrapper span {
    text-align: center;
    background: var(--light-blue2);
    display: block;
    overflow-wrap: break-word;
    border-radius: var(--radius);
    margin: 5px;
    padding: 5px;
}

.form-select {
    width: max-content;
    background-color:white;
    cursor:pointer;
}
.form-select:active,
.form-select:focus,
.form-select:hover,
.form-select:checked{
    outline: none !important;
    box-shadow: none;
}

.form-check-input:checked {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;

  }
  .form-check-input:active,
.form-check-input:focus,
.form-check-input:hover,
.form-check-input:checked{
    outline: none !important;
    box-shadow: none;
}

.template-line {
    height: 20px;
    background-color: var(--grey-dark);
    border-radius: var(--radius) !important;
}
.template-box {
    min-height: 60px !important;
    background-color: var(--grey-dark2) !important;
    border-radius: var(--radius) !important;
}

.dropdown-item {
    cursor: pointer;
}
.dropdown-item:hover, .dropdown-item:focus {
    background-color: transparent;
    color:var(--primary);
  }
  .dropdown-item.active, .dropdown-item:active {

    background-color: transparent;
    color:var(--primary);

  }

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    background-color: black;
  }
  /* When you mouse over the container, fade in the overlay icon*/
  .overlay-container{
        position: relative;
        width: 100%;
  }
  .overlay-content{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .3s ease;
    padding:10px;

}
.overlay-links{
    opacity: 0;
    transition: .3s ease;
    position:absolute;
    z-index:1;
    /*right:0px;*/
    /*margin:10px;*/
}
.overlay-parent {
    position: relative;

}
.overlay-parent:hover .overlay {
    opacity: 0.5;
}
.overlay-parent:hover .overlay-content{
    opacity: 1;
 }
 .overlay-parent:hover .overlay-links {
    opacity: 1;
 }

 .flex-images { overflow: hidden;width:100%;margin-bottom: 30px; }
 .flex-images .item { float: left; margin: 5px; box-sizing: content-box; overflow: hidden; position: relative;background-color: white;}
 .flex-images .item img { display: block; width: auto;}

.demo-files img {
    max-height: 160px;
    cursor:zoom-in;
}
.demo-carousel-indicators{
    white-space: nowrap;
    display:flex !important;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}
.sk-item{
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(15em, 1fr)) !important;
}
.sk-item-icons{
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(7em, 1fr)) !important;
}

.w-85 {
    width: 85% !important;
}
 .wrap {
    white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap;          /* Chrome & Safari */
    white-space: -pre-wrap;                 /* Opera 4-6 */
    white-space: -o-pre-wrap;               /* Opera 7 */
    white-space: pre-wrap;                  /* CSS3 */
    word-wrap: break-word;                  /* Internet Explorer 5.5+ */
    word-break: break-word;
    white-space: normal;
}

.my-nav-tabs{
    border-radius: var(--radius);
    padding-top:10px;
    padding-bottom:10px;
    white-space: nowrap;
    display:flex !important;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

}
.my-nav-tabs li{
    margin-right:10px;
    background-color: white;
    text-align:center !important;
    border-radius: var(--radius);
    border:1px solid var(--grey);

}
.my-nav-tabs .nav-link {
    background-color: transparent !important;
    border-bottom: 3px solid var(--primary-color);
    margin:auto;
    padding-left:10px;
    padding-right:10px;
    border-bottom: none;
    border-radius: var(--radius) !important;
  }
  .my-nav-tabs .nav-link.active, .nav-nav-tabs .show > .nav-link {
    color: white !important;
    background-color: var(--primary-color) !important;
  }

  .dropdown-menu.columns-1 {
    min-width: 300px;
  }
  .dropdown-menu.columns-1 .dropdown-col{
    display:inline-block;
    width: 100%;
  }


  .columns-2 .dropdown-col{
    float:left;
    width: 50%;
  }
  .columns-2.show{

    overflow:auto !important;
  }

  .columns-3 {
    min-width: 900px;
  }
  .columns-3 .dropdown-col{
    float:left;
    width: 33%;
  }
  .notification {
    color: white;
    background-color: var(--info) !important;
    border-color: var(--info) !important;
    border-radius:var(--big-radius) !important;
    width:25%;
  }

  .tooltip-buttons{
    position: absolute;
    margin:5px;
    float:right;
    z-index:1;
  }
  .art-wrapper img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    filter: brightness(75%);
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    width: 100%;
  }

    .bubble-container{
        width:50%;
    }
   .flex-title {
    display:flex;
    align-items:flex-end;
    justify-content:space-between
   }


   .button-list .button {
    display:flex;
    white-space:nowrap
   }
   .button-list .button i {
    float:none
   }
   .button-list .button span {
    max-width:210px;
    overflow:hidden;
    text-overflow:ellipsis
   }
   .bubble-list {
    justify-content:flex-start
   }
   .bubble-list--item {
    width:100%;
    display:block;
    margin-bottom:20px;
    margin-top:20px;
    text-align:center;

   }
   .bubble-list--item:hover .bubble-list--degree {
    background:linear-gradient(225deg, #0161cd 15%, rgba(179,179,252,0.6) 85%)
   }
   .bubble-list--item:hover .bubble-list--border:before {
    opacity:0
   }
   .bubble-list--item:hover .bubble-list--image {
    top:-3px;
    left:-3px;
    width:calc(100% + 6px);
    height:calc(100% + 6px)
   }

   .bubble-list--degree {
    position:relative;
    width:130px;
    height:130px;
    background:linear-gradient(225deg, #0161cd 15%, #b3b3fc 85%);
    border-radius:50%;
    padding:2px;
    margin:0 auto
   }
   .bubble-list--degree-sm {
    width:120px !important;
    min-width:120px !important;
    height:120px !important;
   }
   .bubble-list--border,
   .bubble-list--border:before,
   .bubble-list--image {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%
   }
   .bubble-list--border {
    position:relative;
    border-radius:50%;
    border:3px solid #ffffff;
    background:#ffffff;
    overflow:hidden
   }
   .bubble-list--image {
    object-fit:cover;
    object-position:center;
    border-radius:50%;
    transition:0.2s all ease-out
   }
   .bubble-list--title {
    font-size:18px;
    line-height:26px;
    margin-top:15px;
    font-weight:400;
    margin:10px 0 0;
    text-align:center;
    transition:0.2s all ease-out
   }
   @media screen and (min-width: 768px) {
    .bubble-list--item {
        margin-bottom:0px;
        margin-top:0px;
    }
    .bubble-list--degree {
        width:120px;
        min-width:120px;
        height:120px;
        padding:3px
    }
    .bubble-list--degree-sm {
        width:120px !important;
        min-width:120px !important;
        height:120px !important;
       }
    .bubble-container{
        width:20%;
    }

    .columns-2 {
        min-width: 600px;
      }
   }
   @media screen and (min-width: 1096px) {

    .bubble-list--item {

     margin-bottom:0px;
        margin-top:0px;
    }

    .bubble-container{
        width:20%;
    }
    .bubble-list--degree {
     width:180px ;
     min-width:180px;
     height:180px;
     padding:3px
    }
    .bubble-list--degree-sm {
        width:130px !important;
        min-width:130px !important;
        height:130px !important;
       }
}

.navbar-toggler {
    border: none;
    box-shadow: none !important;
    font-size:30px;
  }

  .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {

    border-color: var(--light-blue);
  }
  @media (min-width: 1200px) {
    .collapse.dont-collapse-xl {
      display: block;
      height: auto !important;
      visibility: visible;
    }
  }
  .carousel-indicators {
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
  }

  [data-bs-toggle="dropdown"].custom-caret:after {
    font-family: "icomoon";
    content: "\e935";
  }

  [data-bs-toggle="dropdown"].custom-caret.show:after{
    content: "\e938" !important;
  }
  .custom-caret {
    display: flex;
  }
  .custom-caret:after {
    border: none;
  }
  .form-check-input {
    clear: left;
  }

  .form-switch.form-switch-sm {
    margin-bottom: 0.5rem; /* JUST FOR STYLING PURPOSE */
  }

  .form-switch.form-switch-sm .form-check-input {
    height: 1rem;
    width: calc(1rem + 0.75rem);
    border-radius: 2rem;
  }

  .form-switch.form-switch-md .form-check-input {
    height: 1.5rem;
    width: calc(2rem + 0.75rem);
    border-radius: 3rem;
  }

  .form-switch.form-switch-lg .form-check-input {
    height: 2rem;
    width: calc(3rem + 0.75rem);
    border-radius: 4rem;
  }

  .form-switch.form-switch-xl {
    margin-bottom: 2rem; /* JUST FOR STYLING PURPOSE */
  }

  .form-switch.form-switch-xl .form-check-input {
    height: 2.5rem;
    width: calc(4rem + 0.75rem);
    border-radius: 5rem;
  }
  .all-overlay{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }

